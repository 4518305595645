import ReduxSagaFirebase from "redux-saga-firebase";

const theme = {};

let colors ={
  color0:'transparent',
  color1:"#ffffff",     
  color2:"#000000",
  color3:"#FF961A",
  color4:"#ffffff",
  color5:'#008bd1',
  color6:'#008bd1',
  color7:'#e5e5e5',
  color8:'#e5e5e5',
  color9:'#064059',
  color10:'#999999',
  color11:'#333333',
  color12:'#1D334D',
  color13:'#e34b9a',   //colores diferente para 24e y m360
  color14:'rgba(255,255,255,0.4)',
  color15:'rgba(2,1,12,0.4)',
  color16:'#ff9700',
  color17:'#008bd1',
  color18:'#ff6801',
  color19:'#005689',
  color20:'#04b7b3',
  color21:'#f4f4f4',
  color22:'#eff2f7',
}

theme.invertido = ""  //agregar invertido como parámetro

theme.palette = {
  //color loading
  primary: ['white'],
  secondary: ['red'],

  //tipografia
  colorText:[colors.color2],
  colorTitle:[colors.color5],
  colorButtonMain:[colors.color3],
  colorLink:[colors.color3],
  colorBulletTabs:[colors.color3],

  //sidebar
  bgSidebar:[colors.color4],
  colorLinkSidebar:[colors.color3],
  colorTextSidebar:[colors.color2],

  //Content
  bgContent:[colors.color5],
  bgLayout:[colors.color4],

  //Topbar
  bgTopbar:[colors.color6],

  //ContentBlue
  bgContentBlue:[(theme.invertido == "invertido" ? colors.color1:colors.color5)],
  colorContentBlue:[(theme.invertido == "invertido" ? colors.color2:colors.color1)],
  bgContentBlueInverso:[theme.invertido == "invertido" ? colors.color5: colors.color15],
  colorContentBlueInverso:[colors.color1],

  //video
  bgSidebarVideo: [colors.color6],
  borderSidebarVideo: [colors.color3],
  colorTabsVideo: [colors.color1],
  bgItemTabsVideo:[colors.color5],
  bgEmbedContainerVideo:[colors.color2],
  
  //login
  colorTextLogin:[colors.color2],
  bgTextLogoLogin:[colors.color0],
  colorTextLogoLogin:[colors.color3],
  colorTextButtonLogin:[colors.color1],
  colorBorderButtonLogin:[ (theme.invertido == "invertido" ? colors.color1:colors.color1)],
  bgButtonLogin:[colors.color0],
  colorInputLogin:[colors.color11],
  bgInputLogin:[colors.color1],
  colorBorderInputLogin:[colors.color15],//login placeholder
  bgColorLogin:[colors.color22],

  //course
  bgCourse:[colors.color5],
  bgTopbarCourse:[colors.color6],
  bgBack:[colors.color4],
  colorTextCourse:[colors.color2],
  colorButtonCourse:[colors.color3],

  //faq
  colorTextFaq:[colors.color1],

  //miperfil
  bgProfile:[(theme.invertido == "invertido" ? colors.color8:colors.color4)],
  colorTitleProfile:[(theme.invertido == "invertido" ? colors.color4:colors.color1)],
  colorLineaProfile:[colors.color14],
  linkFilesProfile:[colors.color6],

  
  //progress curso
  colorTitleProgress:[(theme.invertido == "invertido" ? colors.color11:colors.color5)],
  colorLineaProgress:[(theme.invertido == "invertido" ? colors.color11:colors.color10)],
  bgProgress:[(theme.invertido == "invertido" ? colors.color1:colors.color1)],
  // colorSmallProgramasCurso:[colors.color10],
  bgCollapseProgress:[colors.color14],
  colorTitleCollapseProgress:[colors.color3],

  //recursos
  bgRecursosA:[colors.color16],
  bgRecursosB:[colors.color17],
  bgRecursosC:[colors.color18],
  bgRecursosD:[colors.color19],
  bgRecursosE:[colors.color20],

  //Notificacion
  colorNotificacion:[colors.color1],
  bgNotificacion:[colors.color3],

  //metas personales
  bgMetasPersonales:[colors.color5],
  colorMetasPersonales:[colors.color1],
  colorIconsMetasPersonales:[colors.color3],
  colorTasksMetasPersonales:[colors.color10],

  //Cargas y Herramientas
  colorCargasHerramientas:[colors.color3],
  bgCargasHerramientas:[colors.color1],

  //calendario
  colorLineaCalendario:[colors.color10],
  bgItemCalendario:[colors.color7],
  colorItemCalendario:[colors.color1],
  colorBulletSquareItem:[colors.color3],

  //modal
  bgModal:['#eff6f9'],
  colorTitleModal:[colors.color5],
  colorModal:[colors.color11],
  bgConfirmModal:[colors.color3],

  //lectura 1
  colorLectura1:[colors.color2],
  colorFillLectura:[colors.color3],

  //lectura 2
  colorLectura2:[colors.color1],
  bgLectura2:[colors.color1],

  //editor de texto
  colorEditorTexto:[colors.color2],

  //programas - curso
  bgProgramasCurso:[(theme.invertido == "invertido" ? colors.color8:colors.color4)],
  colorSmallProgramasCurso:[colors.color10],
  colorContentTabsProgramasCurso:[(theme.invertido == "invertido" ? colors.color2:colors.color1)],
  colorTabsProgramasCurso:[(theme.invertido == "invertido" ? colors.color10:colors.color3)],
  colorTabsActiveProgramasCurso:[(theme.invertido == "invertido" ? colors.color4:colors.color1)],
  colorBorderTabsProgramasCurso:[colors.color3],
  bgRecursosCurso:[colors.color7],
  colorBorderRecursosCurso:[colors.color15],
  colorTitleRecursosCurso:[colors.color5],
  colorTextRecursosCurso:[colors.color11],


  //programas - proyectos
  colorDiagramaProgramasProyecto:[colors.color11],
  bgProgramasProyecto:[colors.color7],
  bgTabsProgramasProyecto:[colors.color4],
  colorTabsProgramasProyecto:[colors.color3],
  colorTabsActiveProgramasProyecto:[colors.color5],
  colorSmallProgramasProyecto:[colors.color2],

  colorTabsProyecto:[colors.color14],
  colorTabsHoverProyecto:[colors.color1],
  colorTabsBorderProyecto:[colors.color3],
  bgContentBlueProyecto:[colors.color1],
  colorSmallProyecto:[colors.color11],
  colorTitleProyecto:[colors.color5],
  colorTextInfoEditor:[colors.color11],

  colorResumenOds:[colors.color11],
  bgResumenOds:[colors.color8],

  //item - proyectos
  colorItemProyectos:[(theme.invertido == "invertido" ? colors.color2:colors.color1)],
  bgItemProyectos:[(theme.invertido == "invertido" ? colors.color1:colors.color21)],
  // titleItemProyectos:[(theme.invertido == "invertido" ? colors.color4:colors.color1)],
  titleItemProyectos:[colors.color2],

  //Editar contenido
  colorEditTitle:[colors.color1],

  //Save Editar
  colorSaveEdit:[colors.color5],
  fillSaveEdit:[colors.color3],

  //colores adicionales
  colorWhite:[colors.color1],
  colorWhiteVar1:[colors.color14],
  colorWhiteVar2:[colors.color14],
  colorWhiteVar3:[colors.color14],
  colorWhiteVar4:[colors.color14],
  colorSmall:[colors.color10],
  colorGray1:[colors.color10],
  colorGray2:[colors.color10],
  colorGray3:[colors.color9],
  colorGray4:[colors.color11],
  colorGray5:[colors.color10],
  colorGray6:[colors.color11],
  colorGray7:[colors.color10],
  colorGray8:[colors.color3],
  colorGray9:[colors.color10],
  colorBlack:[colors.color2],
  colorBlackVar1:[colors.color11],
  colorBlackVar2:[colors.color12],
  colorBlackVar3:[colors.color11],
  colorBlackVar4:[colors.color12],
  colorBlackVar5:[colors.color8],
  transparent:[colors.color0],
}

theme.fonts = {
  primary: 'Roboto, sans-serif',
  secondary: 'Poppins, sans-serif',
  size:'16px',
  pre: 'Consolas, Liberation Mono, Menlo, Courier, monospace',
  tipo: 'invertido'

};

export default theme;
