// const BaseURL = 'http://taller24e-melian-v2-backend.sitios.mjh/api';
const BaseURL = 'https://app-end-portal.taller24e.org/api';

const logos = {
  logoTopbar: 'logo_main.svg',
  logoLogin: '24E_logo.svg',
  backgroundLogin: 'background24E_login.jpg',
  logo_inicio_courses: 'icon_logo_m360.svg',
}

const textos = {
  textoLogoLogin: 'Mujeres 360º - 2022',
  bienvenido: 'Bienvenido/a',
  tituloDashboard: "Sobre Mujeres 360",
  textoDashboard: `El programa Mujeres 360 desarrolla habilidades en tecnología digital enfocada en los empleos del futuro
  y el desarrollo de habilidades blandas con el objetivo e independencia económica de mujeres jóvenes Utilizamos la tecnologías
  digitales no como objetivo sino como un instrumento y catalizador para ayudar a reducir las desigualdades.`
}

const auxpiciadores = [
  {
    logo: 'logo1.svg',
    width: 103,
    height: 62,
    colSize: 8,
  },
  {
    logo: 'logo2.svg',
    width: 139,
    height: 42,
    colSize: 8,
  },
  {
    logo: 'logo3.svg',
    width: 104,
    height: 34,
    colSize: 8,
  },
  {
    logo: 'logo8.svg',
    width: 92,
    height: 56,
    colSize: 8,
  },
  {
    logo: 'logo4.svg',
    width: 113,
    height: 35,
    colSize: 8,
  },
  {
    logo: 'logo6.svg',
    width: 129,
    height: 35,
    colSize: 8,
  },
  {
    logo: 'logo7.svg',
    width: 106,
    height: 66,
    colSize: 8,
  }
]

const logosLogin = [
  {
    logo: 'logo_efut.png',
    width: 159,
    height: 48
  },
  {
    logo: 'logo_be.png',
    width: 166,
    height: 48
  }
]

export {
  BaseURL,
  logos,
  textos,
  auxpiciadores,
  logosLogin
}