import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import { createBrowserHistory } from 'history';
import JwtAuthentication from '@iso/lib/jwt-auth/jwtAuthentication';

import { getUserData, clearUserData, setUserData } from '@iso/lib/helpers/utility';
import actions from './actions';
import notification from '@iso/components/Notification';

const history = createBrowserHistory();

export function* loginRequest() {
  yield takeEvery('LOGIN_REQUEST', function*({ payload }) {
    const result = yield call(JwtAuthentication.login, payload.userInfo);
    let initialForm = getUserData().get('initialForm');
    if (result.success) {
      if (!result.initialForm) {
        yield setUserData(result.token,result.userName,result.profilePicture,result.lessons?result.lessons:[], initialForm);
      }
      yield put({
        type: actions.LOGIN_SUCCESS,
        token: result.token,
        profile: 'Profile',
        userName: result.userName?result.userName:'',
        profilePicture: result.profilePicture?result.profilePicture:'',
        lessons: result.lessons?result.lessons:[],
      });
    } else {
      notification('error', result.error?result.error:'Sorry, there was an error with your request');
      yield put({ type: actions.LOGIN_ERROR });
    }
  });
}

export function* loginSuccess() {
  yield takeEvery(actions.LOGIN_SUCCESS, function*(payload) {
    let initialForm = getUserData().get('initialForm');
    yield setUserData(
      payload.token,
      payload.userName,
      payload.profilePicture, 
      payload.lessons,
      initialForm);
    let path = window.location.href
    if(!path.includes('groupchange')){
      if (!initialForm || initialForm === 'undefined' || initialForm === 'null' ) {
      history.push('/initialForm');
      }
    }
  });
}

export function* groupChange() {
  yield takeEvery(actions.GROUP_CHANGE, function*() {
    let path = window.location.href
    if(!path.includes('groupchange')){
      yield put({ type: actions.LOGIN_ERROR });
    }
  });
}

export function* loginError() {
  yield takeEvery(actions.LOGIN_ERROR, function*() {
    yield clearUserData();
    history.push('/');
  });
}

export function* logout() {
  yield takeEvery(actions.LOGOUT, function*() {
    yield call(JwtAuthentication.logout, {});
    yield clearUserData();
    history.push('/');
    window.location.reload()
  });
}
export function* checkAuthorization() {
  yield takeEvery(actions.CHECK_AUTHORIZATION, function*() {
    const token = getUserData().get('idToken');
    const userName = getUserData().get('userName');
    const profilePicture = getUserData().get('profilePicture');
    const lessons = getUserData().get('lessons');
    if (userName && (token && token !=='undefined')) {
      yield put({
        type: actions.LOGIN_SUCCESS,
        token,
        userName,
        profilePicture,
        lessons,
      });
    } else {
      yield put({ type: actions.GROUP_CHANGE });
    }
  });
}

/*export function* selectedCourseSuccess() {
  yield takeEvery(actions.SELECTED_COURSE_SUCCESS, function*(payload) {
    const token = getUserData().get('idToken');
    const userName = getUserData().get('userName');
    const profilePicture = getUserData().get('profilePicture');
    const lessons = getUserData().get('lessons');
    const selectedCourse = payload.selectedCourse
    yield setUserData(
      token,
      userName,
      profilePicture, 
      lessons,
      selectedCourse
    );
    yield history.push('/dashboard/Curso/' + selectedCourse);
  })
}*/

export function* initialFormSuccess () {
  yield takeEvery(actions.INITIAL_FORM_SUCCESS, function*(payload) {
    const token = getUserData().get('idToken');
    const userName = getUserData().get('userName');
    const profilePicture = getUserData().get('profilePicture');
    const lessons = getUserData().get('lessons');
    const initialForm = payload.initialForm;
    yield setUserData(
      token,
      userName,
      profilePicture,
      lessons,
      initialForm,
    );
    yield history.push('/dashboard/Curso/4');
    yield window.location.reload()
  });
}

export default function* rootSaga() {
  yield all([
    fork(checkAuthorization),
    fork(loginRequest),
    fork(loginSuccess),
    fork(loginError),
    fork(logout),
    fork(initialFormSuccess),
    fork(groupChange),
    //fork(selectedCourseSuccess),
  ]);
}
